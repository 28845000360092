.iconButton {
  border: 1px solid #717171 !important;
  background-color: #fff !important;
}
.iconButton:hover {
  border: 1px solid #000 !important;
}

.iconButton:disabled {
  border: 1px solid #ebebeb !important;
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

.endIcon {
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.endIconRotated {
  transform: rotate(-180deg);
}

.datesPicker {
  display: inline;
  flex-direction: column;
}

.buttonsBox {
  display: flex !important;
  justify-content: right !important;
  align-items: center !important;
  bottom: 0px !important;
  padding: 0 32px !important;
}

.saveBtn:hover {
  color: #fff !important;
  background: #000 !important;
}

.btnBox {
  margin-bottom: 16px !important;
  margin-right: 32px !important;
  right: 0px !important;
  bottom: 0px !important;
  width: 100% !important;
  overflow: hidden !important;
}

.cardWrapper {
  display: flex !important;
  flex-direction: column !important;
  background-color: #EEF6FB;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.cardDate {
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
  align-items: flex-end !important;
}

.cardHours {
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
  align-items: center !important;
  /* border-bottom: 1px solid rgb(221, 221, 221) !important; */
  margin: 10px 0 !important;
  padding: 8px 12px !important;
  background: #3C96D2;
  color: white;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.chooseBtn {    
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%) !important; 
  border-radius: 8px !important; 
  margin: 2px 10px 10px 2px !important;
  text-transform: capitalize !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  display: block !important;
  transition: 0.5s;
}

.chooseBtn:focus {
  background-position: calc((100 - var(--mouse-x, 0)) * 1%)
    calc((100 - var(--mouse-y, 0)) * 1%) !important;
  --mouse-x: 22.1063 !important;
  --mouse-y: 36.9792 !important;
  transition: opacity 1.25s ease 0s !important;
}

.dateSearch .PrivateDatePickerToolbar-penIcon {
  display: none;
}

.dateSearch button:enabled {
  color: #3C96D2;
  font-weight: bold;
}